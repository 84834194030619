import React from 'react';
import './Header.scss';

export const Header = () => {

  return (
    <div className="headerContainer">
        <header>
            <h1 className="title">
                COMING SOON...
            </h1>
        </header>
    </div>
  );
};