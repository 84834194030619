import logo from './logo.svg';
import { Header } from './components/header/index';
import './App.scss';

function App() {
  return (
    <div className="App shapedividers_com-1149">
      <Header></Header>
    </div>
  );
}

export default App;
